import logo from './logo.svg';
import './App.css';
import { useRef } from "react";
import { motion } from "framer-motion"

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const textAnimation = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const imageAnimation = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  },
};

const App = () => (
  <motion.div
    className="container"
    variants={container}
    initial="hidden"
    animate="visible"
  >
    <motion.h1 className='titleText' variants={textAnimation}>Hoi</motion.h1>
    <motion.div className='imageDiv' transition={{ delay: 1 }} variants={imageAnimation}><img className='image' src='https://picsum.photos/200'></img></motion.div>
  </motion.div>
);

export default App